import * as React from "react";
import { shimmer, toBase64 } from "@src/utils/shimmer";
import Image from "next/image";
import styles from "./hero.module.scss";
import { useViewportScroll, motion, useTransform } from "framer-motion";
import Dialog from "@src/components/Dialog";
import Invitation from "@src/components/modals/Invitation";
import { eventBySlug, getHeroImage } from "@src/@types/api.d";
import image from "../../../public/images/image10.png";
import { getCookie } from "cookies-next";

export type IHero = {
  eventId: number;
  slugData: eventBySlug | undefined;
  heroImageData: getHeroImage;
  loading?: boolean;
  eventUuid?: string;
};

const Hero: React.FC<IHero> = ({
  eventId,
  heroImageData,
  slugData,
  loading,
  eventUuid,
}) => {
  const { scrollY } = useViewportScroll();
  const y1 = useTransform(scrollY, [0, 300], [0, 60]);
  const currentAccessToken = getCookie("tsl") as string;
  const [openRsvp, setOpenRsvp] = React.useState<boolean>(false);
  const [show, setShow] = React.useState<boolean>(false);
  // const [openRequest, setOpenRequest] = React.useState<boolean>(false);

  React.useEffect(() => {
    setShow(true);
  }, []);

  return (
    <div className={styles["home__slider"]}>
      <motion.div
        initial={{ scale: 1 }}
        animate={{
          scale: 1.1,
          transition: {
            delay: 0.3,
            duration: 0.8,
            ease: [0.79, 0.14, 0.15, 0.86],
          },
        }}
        className={styles["home__slider-image"]}
      >
        <Image
          placeholder="blur"
          loading="eager"
          layout="fill"
          blurDataURL={`data:image/svg+xml;base64,${toBase64(
            shimmer(700, 475)
          )}`}
          src={
            !!heroImageData.getHeroImage ? heroImageData.getHeroImage : image
          }
          alt="gallery-image"
        />
      </motion.div>

      <div className={styles["home__slider-text"]}>
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{
            opacity: 1,
            transition: {
              delayChildren: 1,
              staggerChildren: 1.5,
            },
          }}
          viewport={{ once: true }}
          className={styles["home__slider-text-container"]}
        >
          <motion.h5
            initial={{ opacity: 0, y: 30 }}
            whileInView={{
              opacity: 1,
              y: 0,
              transition: {
                duration: 1,
                ease: [0.79, 0.14, 0.15, 0.86],
              },
            }}
            className={styles["home__slider-title"]}
          >
            {loading
              ? "Welcome"
              : !!slugData?.eventBySlug && !!slugData?.eventBySlug?.welcome_msg
              ? slugData?.eventBySlug?.welcome_msg
              : "Welcome"}
          </motion.h5>
          <motion.p
            initial={{ opacity: 0, y: 30 }}
            whileInView={{
              opacity: 1,
              y: 0,
              transition: {
                duration: 1,
                ease: [0.79, 0.14, 0.15, 0.86],
              },
            }}
            className={styles["home__slider-subtitle"]}
          >
            {loading
              ? "..."
              : !!slugData?.eventBySlug && !!slugData?.eventBySlug?.hash_tag
              ? slugData?.eventBySlug?.hash_tag
              : ""}
          </motion.p>
          <motion.div
            initial={{ opacity: 0, y: 30 }}
            whileInView={{
              opacity: 1,
              y: 0,
              transition: {
                duration: 1,
                ease: [0.79, 0.14, 0.15, 0.86],
              },
            }}
            className={styles["home__slider-text-button"]}
          >
            {show && currentAccessToken ? (
              <div className={styles["home__slider-text-button-container"]}>
                <div className={styles["home__slider-rsvp-button"]}>
                  {/* <Dialog
									trigger={
										<motion.div
											style={{ y: y1 }}
											className={styles["home__slider-rsvp-button__act"]}
											onClick={() => setOpenRequest(true)}
										>
											Request an invite
										</motion.div>
									}
									open={openRequest}
									onOpenChange={setOpenRequest}
								>
									<RequestAccessModal eventId={eventId} setOpen={setOpenRequest} />
								</Dialog> */}
                  <Dialog
                    trigger={
                      <motion.div
                        style={{ y: y1 }}
                        className={styles["home__slider-rsvp-button__act"]}
                        onClick={() => setOpenRsvp(true)}
                      >
                        RSVP
                      </motion.div>
                    }
                    open={openRsvp}
                    onOpenChange={setOpenRsvp}
                  >
                    <Invitation
                      name={slugData?.eventBySlug?.name ?? ""}
                      date={slugData?.eventBySlug?.date ?? ""}
                      eventId={eventId}
                      setOpen={setOpenRsvp}
                      access_control={
                        slugData?.eventBySlug?.event_preference
                          ?.access_control ?? false
                      }
                      eventUuid={eventUuid}
                      eventSlug={slugData?.eventBySlug?.slug ?? ""}
                      passImage={slugData?.eventBySlug?.pass_image ?? ""}
                    />
                  </Dialog>
                </div>
              </div>
            ) : (
              <></>
            )}
          </motion.div>
        </motion.div>
      </div>
      <div className={styles["home__slider-overlay"]}></div>
    </div>
  );
};

export default Hero;
