import {
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  ApolloLink,
} from "@apollo/client";
import fetch from "isomorphic-unfetch";
import { onError } from "@apollo/client/link/error";
import { makeVar } from "@apollo/client";
import { useMemo } from "react";
import { getNewToken } from "@src/utils/getNewToken";

let apolloClient = null;

export const networkError = makeVar(false);

function initApolloClient(initialState = {}, token = "", refreshToken, ctx) {
  // Make sure to create a new client for every server-side request so that data
  // isn"t shared between connections (which would be bad)

  if (typeof window === "undefined") {
    return createApolloClient(initialState, token, refreshToken, ctx);
  }

  // Reuse client on the client-side
  if (!apolloClient) {
    // @ts-ignore
    apolloClient = createApolloClient(initialState, token, refreshToken, ctx);
  }

  return apolloClient;
}

export function withApollo(PageComponent, { ssr = true } = {}) {
  const WithApollo = ({
    apolloStaticCache,
    token,
    refreshToken,
    ...pageProps
  }) => {
    const client = initApolloClient(apolloStaticCache, token, refreshToken);

    return (
      <ApolloProvider client={client}>
        <PageComponent {...pageProps} />
      </ApolloProvider>
    );
  };

  //  for SSR
  if (PageComponent.getServerSideProps) {
    WithApollo.getServerSideProps = async () => {
      // Run wrapped getServerSideProps methods
      let pageProps = {};
      if (PageComponent.getServerSideProps) {
        pageProps = await PageComponent.getServerSideProps(ctx);
      }
      return pageProps;
    };
  }

  // Set the correct displayName in development
  if (process.env.NODE_ENV !== "production") {
    const displayName =
      PageComponent.displayName || PageComponent.name || "Component";

    if (displayName === "App") {
      console.warn("This withApollo HOC only works with PageComponents.");
    }

    WithApollo.displayName = `withApollo(${displayName})`;
  }

  return WithApollo;
}

export function useApollo(initialState = {}, token = "", refreshToken = "") {
  const store = useMemo(
    () => initApolloClient(initialState, token, refreshToken),
    [initialState, token, refreshToken]
  );
  return store;
}

export function createApolloClient(initialState = {}, token, refreshToken) {
  const authLink = new ApolloLink((operation, forward) => {
    operation.setContext({
      headers: {
        "x-user-token": token,
        "Cache-Control": "no-cache, no-store, must-revalidate",
        Pragma: "no-cache",
        Expires: "0",
      },
    });

    // Call the next link in the middleware chain.
    return forward(operation);
  });

  const httpLink = createHttpLink({
    uri: process.env.API_URI || process.env.NEXT_PUBLIC_API_URI,
    fetch,
  });

  const errorLink = onError(
    ({ networkError, graphQLErrors, operation, forward }) => {
      if (graphQLErrors) {
        for (let error of graphQLErrors) {
          switch (error.message) {
            case "unauthenticated":
              console.error("GraphQL UnAuth error:>> ", error);
              // if (refreshToken !== "") {
              //   try {
              //     getNewToken(refreshToken);
              //   } catch (error) {
              //     console.log(error);
              //   }
              // }
              // retry the request, returning the new observable
              return forward(operation);
          }
        }
      }
      if (networkError) {
        console.error("Network error:>> ", networkError);
      }
    }
  );

  return new ApolloClient({
    ssrMode: typeof window === "undefined", // Disables forceFetch on the server (so queries are only run once)
    link: errorLink.concat(authLink.concat(httpLink)),
    cache: new InMemoryCache().restore(initialState),
  });
}

export default initApolloClient;
