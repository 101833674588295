import { gql } from "@apollo/client";

export const GetHeroImage = gql`
  query getHeroImage($eventId: Int!) {
    getHeroImage(event_id: $eventId)
  }
`;

export const GetEventStreamers = gql`
  query getEventStreamers($eventId: Int!) {
    getEventStreamers(eventId: $eventId) {
      Id
      is_streaming
      playback_url
      deviceName
      viewingUrl
      event {
        event_preference {
          live_hall
          private
        }
      }
    }
  }
`;

export const EventBySlug = gql`
  query eventBySlug($slug: String!) {
    eventBySlug(slug: $slug) {
      welcome_msg
      slug
      id
      uuid
      name
      hash_tag
      template_id
      date
      pass_image
      event_preference {
        access_control
      }
      event_wallet {
        balance
        currency
        id
        frozen
      }
    }
  }
`;

export const EventParts = gql`
  query eventParts($eventId: Int!) {
    eventParts(event_id: $eventId) {
      id
      name
      part
      time
      city
      address
      map_coordinate
      geo_position {
        geo_position_id
        latitude
        longitude
      }
    }
  }
`;

export const Refresh = gql`
  query refresh($refreshToken: String!) {
    refresh(refresh_token: $refreshToken) {
      access_expiry
      access_token
      refresh_expiry
      refresh_token
      user {
        first_name
        id
        email
        last_name
        phone_number
        verified
      }
    }
  }
`;

export const GetBackStory = gql`
  query getBackStory($eventId: Int!) {
    getBackStory(event_id: $eventId) {
      id
      role
      story
      title
      image
      event_id
    }
  }
`;

export const InvitationCard = gql`
  query invitationCard($eventId: Int!) {
    invitationCard(event_id: $eventId) {
      template
      id
    }
  }
`;

export const GetAlbumDetails = gql`
  query getAlbumDetails($eventUuid: String!, $page: Int, $pageSize: Int) {
    getMedia(
      event_id: $eventUuid
      filter: { page: $page, pageSize: $pageSize }
    ) {
      id
      url
      meta {
        s3_url
        height
        width
      }
    }
  }
`;

export const GetWishListItems = gql`
  query getWishListItems($eventId: Int!, $page: Int, $pageSize: Int) {
    getWishListItems(event_id: $eventId, page: $page, pageSize: $pageSize) {
      id
      uuid
      name
      image
      paid
      amount
      currency
      qty
    }
  }
`;

export const GetEventPreferences = gql`
  query eventPreferences($eventId: Int!) {
    event(event_id: $eventId) {
      event_preference {
        id
        food
        ads
        asoebi_selection
        gifts
        store
        vendor_store
        reservation
        dress_code
        private
        live_hall
      }
    }
  }
`;

export const GetApprovedMessages = gql`
  query getApprovedMessages($eventId: Int!) {
    getApprovedMessages(event_id: $eventId) {
      id
      content
      status
    }
  }
`;

// export const GetEventStoreItems = gql`
//   query getEventStoreItems($eventId: Int!, $page: Int, $pageSize: Int) {
//     getEventStoreItems(eventId: $eventId, page: $page, pageSize: $pageSize) {
//       id
//       item_image
//       item_name
//       item_price
//       status
//       currency
//     }
//   }
// `;

export const EventVendor = gql`
  query eventVendor($eventId: Int!, $page: Int, $pageSize: Int) {
    eventVendor(event_id: $eventId, page: $page, pageSize: $pageSize) {
      id
      name
      instagram_handle
      phone_number
      role
      email
    }
  }
`;

export const GetForeignWalletDetails = gql`
  query getForeignAccountDetails($eventId: Int!) {
    getForeignAccountDetails(event_id: $eventId) {
      event_id
      walletId
      account_number
      bank_name
      currency
      beneficiary_name
      swift_code
      country
      city
      bank_address
      post_code
      sort_code
    }
  }
`;

export const GetLiveSchedule = gql`
  query getLiveSchedule($eventUuid: String!, $page: Int, $pageSize: Int) {
    getLiveSchedule(eventUuid: $eventUuid, page: $page, pageSize: $pageSize) {
      page
      pageSize
      pageCount
      recordCount
      data {
        id
        event_uuid
        event_part_uuid
        link
        time
        created_at
        updated_at
        event_part {
          id
          name
          uuid
        }
      }
    }
  }
`;
