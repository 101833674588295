import * as React from "react";
import Button from "@src/components/Button";
import invitation from "./invitation.module.scss";
import toast from "react-hot-toast";
import Image from "next/image";
import { useQuery, useMutation } from "@apollo/client";
import {
  invitationCard,
  invitationCardVariables,
  updateRsvpStatus,
  updateRsvpStatusVariables,
  VerifyMediaUpload,
  VerifyMediaUploadVariables,
  VerifySelfieUploadVariables,
  GetSelfiePresignedUrlVariables,
  GetSelfiePresignedurl,
} from "@src/@types/api.d";
import { InvitationCard } from "@src/graphql/queries";
import {
  UpdateRsvpStatus,
  GetInvitationSelfiePresignedUrl,
  VerifyInvitationSelfieUpload,
} from "@src/graphql/mutations";
import { getCookie } from "cookies-next";
import defaultInviteImage from "../../../../public/images/generalDefaultImage.png";
import html2canvas from "html2canvas";
import axios from "axios";
import { QRCodeCanvas } from "qrcode.react";
import {
  DownloadPassIcon,
  SelfiePlaceholderIcon,
  CameraIcon,
  InawoPassIcon,
} from "@src/components/Icon/Icons";
import { toPng } from "html-to-image";

interface InvitationProps {
  name: string | null;
  date: string;
  eventId: number;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  access_control: boolean;
  eventUuid?: string;
  eventSlug?: string;
  passImage?: string;
}

const Invitation = ({
  date,
  name,
  eventId,
  setOpen,
  access_control,
  eventUuid,
  eventSlug,
  passImage,
}: InvitationProps) => {
  const currentAccessToken = getCookie("tsl") as string;
  const [step, setStep] = React.useState<number>(1);
  const [picture, setPicture] = React.useState<File | null>(null);
  const cardRef = React.useRef<HTMLDivElement>(null);
  const [show, setShow] = React.useState<boolean>(false);

  React.useEffect(() => {
    setShow(true);
  }, []);

  const { data: invitationCardData, loading: invitationCardLoading } = useQuery<
    invitationCard,
    invitationCardVariables
  >(InvitationCard, {
    skip: !eventId,
    variables: {
      eventId,
    },
  });

  const [UpdateRsvpStatusFn, { loading }] = useMutation<
    updateRsvpStatus,
    updateRsvpStatusVariables
  >(UpdateRsvpStatus, {
    onCompleted() {
      if (access_control === true) {
        toast.success(<p className="toast">RSVP sent successfully.</p>);
        setStep(2);
      } else {
        setOpen(false);
        toast.success(<p className="toast">RSVP sent successfully.</p>);
      }
    },
    onError(error) {
      if (error.message !== "unauthenticated") {
        toast.error(
          <p className="toast">{error?.message ?? "An error occured"}</p>
        );
      }
    },
  });

  // const [verifySelfieUpload, { loading: verifySelfieUploadLoading }] =
  //   useMutation<VerifyMediaUpload, VerifySelfieUploadVariables>(
  //     VerifyInvitationSelfieUpload
  //   );

  // const [uploadSelfie, { loading: getMediaPresignedUrlLoading }] = useMutation<
  //   GetSelfiePresignedurl,
  //   GetSelfiePresignedUrlVariables
  // >(GetInvitationSelfiePresignedUrl);

  // const handleMediaUpload = async () => {
  //   if (picture) {
  //     try {
  //       const res = await uploadSelfie({
  //         variables: {
  //           eventId: eventUuid ?? "",
  //           mediaType: picture.type,
  //           invitationId: eventUuid ?? "",
  //         },
  //       });

  //       if (res?.data?.generateInvitationSelfiePreSignedUrl?.upload_url) {
  //         await axios
  //           .put(
  //             res.data.generateInvitationSelfiePreSignedUrl.upload_url,
  //             picture,
  //             {
  //               headers: {
  //                 "Content-Type": picture.type,
  //                 "x-amz-acl": "public-read",
  //               },
  //             }
  //           )
  //           .then(() => console.log("Upload successful"))
  //           .catch((error) => console.error("Axios upload error:", error));
  //         toast.success("Selfie uploaded successfully");

  //         await verifySelfieUpload({
  //           variables: {
  //             eventId: eventUuid ?? "",
  //             invitationId: eventUuid ?? "",
  //             fileUri:
  //               res?.data?.generateInvitationSelfiePreSignedUrl?.file_uri ?? "",
  //           },
  //         })
  //           .then(() => {
  //             toast.success("Selfie verified successfully");
  //             setStep(step + 1);
  //           })
  //           .catch((error) => {
  //             console.error("Selfie verification error:", error);
  //             toast.error("An error occurred while verifying selfie");
  //           });
  //       }
  //     } catch (error) {
  //       console.error("Upload error:", error);
  //       toast.error("An error occurred while uploading");
  //     }
  //   } else {
  //     toast.error("Please select a selfie picture to upload");
  //   }
  // };

  const handleDownload = async () => {
    if (cardRef.current) {
      const dataUrl = await toPng(cardRef.current);
      const link = document.createElement("a");
      link.download = "love-card.png";
      link.href = dataUrl;
      link.click();
    }
  };

  return (
    <div>
      {access_control ? (
        <div className={invitation["invitation--content"]}>
          {step === 1 ? (
            <>
              {invitationCardLoading ? (
                <>Loading...</>
              ) : !!invitationCardData?.invitationCard?.template ? (
                <div className={invitation["invitation--content__image"]}>
                  <Image
                    src={invitationCardData?.invitationCard?.template}
                    layout="fill"
                    alt="Invitation card"
                    objectFit="contain"
                  />
                </div>
              ) : (
                <div className={invitation["invitation--content__image"]}>
                  <Image
                    src={defaultInviteImage}
                    layout="fill"
                    alt="Invitation card"
                    objectFit="contain"
                  />
                </div>
              )}
            </>
          ) : (
            //   : step === 2 ? (
            //   <div className="flex flex-col gap-[40px] items-center w-full lg:w-[510px] mx-auto">
            //     <div className="flex flex-col gap-[12px] items-center">
            //       <h3 className="font-medium text-[26px]">We need a selfie</h3>
            //       <p className="text-[#71717A] text-[14px]">
            //         This is for verification purposes during the wedding
            //       </p>
            //     </div>
            //     <div className="flex flex-col gap-[16px] items-center">
            //       {picture ? (
            //         <img
            //           src={URL.createObjectURL(picture)}
            //           alt="user picture"
            //           className="w-[164px] h-[224px] rounded-[56px] object-cover"
            //           style={{ border: "1px solid #71717A" }}
            //         />
            //       ) : (
            //         <SelfiePlaceholderIcon />
            //       )}
            //       <label
            //         htmlFor="upload"
            //         className="flex items-center gap-[8px] rounded-[8px] py-[8px] px-[12px] font-medium shadow-lg"
            //       >
            //         <input
            //           type="file"
            //           accept="image/*"
            //           onChange={(e) => {
            //             if (e.target.files && e.target.files[0]) {
            //               setPicture(e.target.files[0]);
            //             }
            //           }}
            //           className="hidden"
            //           id="upload"
            //         />
            //         <CameraIcon />
            //         <p>
            //           {picture ? "Change selfie image" : "Select selfie image"}
            //         </p>
            //       </label>
            //     </div>
            //     <div className="w-full">
            //       <button
            //         onClick={handleMediaUpload}
            //         style={{ border: "1px solid #e8b13d" }}
            //         className="w-full mb-[8px] rounded-[8px] flex items-center justify-center p-[16px] text-[14px] text-center"
            //       >
            //         {getMediaPresignedUrlLoading || verifySelfieUploadLoading
            //           ? "uploading selfie..."
            //           : "Continue"}
            //       </button>
            //       <p className="text-[#71717A] text-[14px] text-center">
            //         Works better on your mobile device
            //       </p>
            //     </div>
            //   </div>
            // )
            <div className="flex flex-col gap-[40px] items-center w-full lg:w-[510px] mx-auto">
              <div className="flex flex-col gap-[12px] items-center">
                <h3 className="font-medium text-[26px]">Your pass</h3>
                <p className="text-[#71717A] text-[14px]">
                  Show this at the venue
                </p>
              </div>
              <div className="flex flex-col gap-[16px] items-center">
                {/* <div className="w-[188px] mx-auto h-[300px] rounded-[22px] flex items-center justify-center flex-col gap-[20px] px-[50px] bg-gradient-to-br from-[#026051] via-[#47A092CC] to-[#3B5F5900]"> */}
                <div
                  className="w-[188px] shadow-md h-[300px] rounded-[22px] py-[9px] px-[15px] border border-[#D9D9D9] flex flex-col items-center justify-between"
                  style={{
                    backgroundImage: `url(${passImage})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                  ref={cardRef}
                >
                  <p className="my-[11px] text-[11px] font-medium text-white">
                    #{eventSlug}
                  </p>
                  <div
                    className="w-full rounded-[22px] bg-[#FFFFFFCC] h-[211px] px-[10px] py-[10px] flex flex-col items-center justify-between"
                    style={{ backdropFilter: "blur(3px)" }}
                  >
                    <div>
                      <p className="text-[9px] text-[#4D4D4DE8] mb-[2px]">
                        Guest codeec
                      </p>
                      <p className="text-[10px] text-[#4D4D4D4D]">
                        {"TWE2110"}
                      </p>
                    </div>
                    <div className="w-[105px] h-[105px] border border-[#3D516080] rounded-[20px] bg-gradient-to-b from-[#E6FA5200] to-[#E6FA5233] p-[20px]">
                      <QRCodeCanvas
                        value="TWE2110"
                        fgColor="#4F7770"
                        size={65}
                      />
                    </div>
                    <div className="flex flex-col items-center justify-center">
                      <p className="mb-[2px] text-[#4D4D4D99] text-[10px] text-center">
                        This is your pass to the wedding
                      </p>
                      <InawoPassIcon />
                    </div>
                  </div>
                </div>
                {/* </div> */}
                <button
                  onClick={handleDownload}
                  className="flex items-center justify-center gap-[8px] bg-[#FFFBEB] w-[188px] mx-auto h-[35px] rounded-[7px] py-[7px] w-[188px] mx-auto px-[12px] font-medium shadow-lg"
                  style={{ border: "0.579px solid #5C5C5C" }}
                >
                  <DownloadPassIcon />
                  <p className="text-[#141414] text-[12px] font-normal">
                    Download your pass
                  </p>
                </button>
              </div>
              <div className="w-full">
                <button
                  onClick={() => setStep(6)}
                  style={{ border: "1px solid #e8b13d" }}
                  className="w-full mb-[8px] rounded-[8px] p-[16px] text-[14px] text-center"
                >
                  Continue
                </button>
                <p className="text-[#71717A] text-[14px] text-center">
                  This pass has been sent to your mail
                </p>
              </div>
            </div>
          )}

          {show && !!currentAccessToken && (
            <div className={invitation["invitation--content__bottom"]}>
              <div>
                <Button
                  loading={loading}
                  handleClick={() =>
                    UpdateRsvpStatusFn({
                      variables: {
                        eventId: eventId,
                        status: "yes",
                      },
                    })
                  }
                  type="button"
                  design="another"
                  label="I'm Coming"
                >
                  I&apos;m Coming
                </Button>
              </div>
              <div>
                <Button
                  loading={loading}
                  handleClick={() =>
                    UpdateRsvpStatusFn({
                      variables: {
                        eventId: eventId,
                        status: "no",
                      },
                    })
                  }
                  type="button"
                  design="secondary"
                  label="I'm Not Coming"
                >
                  I&apos;m Not Coming
                </Button>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className={invitation["invitation--content"]}>
          {invitationCardLoading ? (
            <>Loading...</>
          ) : !!invitationCardData?.invitationCard?.template ? (
            <div className={invitation["invitation--content__image"]}>
              <Image
                src={invitationCardData?.invitationCard?.template}
                layout="fill"
                alt="Invitation card"
                objectFit="contain"
              />
            </div>
          ) : (
            <div className={invitation["invitation--content__image"]}>
              <Image
                src={defaultInviteImage}
                layout="fill"
                alt="Invitation card"
                objectFit="contain"
              />
            </div>
          )}

          {show && !!currentAccessToken && (
            <div className={invitation["invitation--content__bottom"]}>
              <div>
                <Button
                  loading={loading}
                  handleClick={() =>
                    UpdateRsvpStatusFn({
                      variables: {
                        eventId: eventId,
                        status: "yes",
                      },
                    })
                  }
                  type="button"
                  design="another"
                  label="I'm Coming"
                >
                  I&apos;m Coming
                </Button>
              </div>
              <div>
                <Button
                  loading={loading}
                  handleClick={() =>
                    UpdateRsvpStatusFn({
                      variables: {
                        eventId: eventId,
                        status: "no",
                      },
                    })
                  }
                  type="button"
                  design="secondary"
                  label="I'm Not Coming"
                >
                  I&apos;m Not Coming
                </Button>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Invitation;
